export const getListStyles = styledProp => {
  const tokenProps = {};
  if (!styledProp) {
    tokenProps.listStyleType = 'none';
  } else {
    tokenProps.paddingInlineStart = '1000';
  }
  return tokenProps;
};
export const getListItemStyles = (use, inline) => {
  const tokenProps = {};
  if (inline || use === 'inline-divided') {
    tokenProps.display = 'block';
  }
  if (use === 'inline-divided') {
    tokenProps.insetInlineStart = '200';
  }
  return tokenProps;
};